import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";

import axios from 'axios';
const SavedLinks = ({ id, authorized }) => {


    const [dataList, setDataList] = useState([])


    const navigate = useNavigate()
    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        loadData()
    }, [authorized])

    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/savedlink/api-get-saved-link.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data)

        })
    }
    return (
        <section className='favorite-section' >
            <div className='favorite-container'>
                <h1>Bevakade sökningar</h1>
                <div className='favorite-content'>
                    {dataList.map((val, i) => {
                        return (
                            <Link key={i} to={`/${val.link}`}>
                                <div className='saved-link-container'>
                                    Sök {i + 1}
                                </div>
                            </Link>

                        )

                    })}

                </div>
            </div>
            <div className='favorite-announce'>

            </div>
        </section>

    )
}

export default SavedLinks