import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { HeaderContext } from './helper/Context';

// conponents
import Header from './Components/Header'
import Footer from './Components/Footer'
import Login from './Components/Login';
// Material
import Home from './Pages/Home/Home'
import Search from './Pages/Search/Search';
import Profile from './Pages/Profile/Profile';
import Favorite from './Pages/Profile/Favorite';
import Announce from './Pages/Profile/Announce';
import SavedLinks from './Pages/Profile/SavedLinks';
import Chat from './Pages/Profile/Chat';
import Item from './Pages/Item/Item';
import AddProduct from './Pages/Add/AddProduct';
import AddHorse from './Pages/Add/AddHorse';
import AddServices from './Pages/Add/AddServices';
import AddEquipment from './Pages/Add/AddEquipment';
import Confirmation from './Pages/Add/Confirmation';
import Edit from './Pages/Edit/Edit';
import Register from './Pages/User/Register';
import RePassword from './Pages/User/RePassword';
import ChangePassword from './Pages/User/ChangePassword';
import VerifyEmail from './Pages/User/VerifyEmail';
import IntegrationPolicy from './Pages/IntegrationPolicy/IntegrationPolicy';
import PersonalDataPolicy from './Pages/IntegrationPolicy/PersonalDataPolicy';

import CookiesBannaer from './Components/CookiesBannaer';

const App = () => {

    const navigate = useNavigate();

    let UUID;
    let username;

    if (localStorage.getItem("User") !== null) {
        let userObject = localStorage.getItem('User');
        UUID = JSON.parse(userObject).UUID;
        username = JSON.parse(userObject).Name;

    }

    // Authrization
    const cookies = new Cookies();
    const token = cookies.get('jwt')
    const [authorized, setAuthorized] = useState(true)

    // Size detection
    const [mobileSize, setMobileSize] = useState(false)
    const [tabletSize, setTabletSize] = useState(false)
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })


    useEffect(() => {
        if (token) {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login_verify.php`, {
                params: {
                    token: token
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.condition === false) {
                    setAuthorized(false)
                } else {
                    setAuthorized(true)
                }
            })
        } else {
            setAuthorized(false)
        }
        if (windowDimenion.winWidth <= 1280) {
            setTabletSize(true)
            setMobileSize(false)
        } else if (windowDimenion.winWidth <= 600) {
            setTabletSize(false)
            setMobileSize(true)
        } else {
            setTabletSize(false)
            setMobileSize(false)
        }
    }, [token, windowDimenion.winWidth])

    const [settings, setSettings] = useState({})

    useEffect(() => {
        // console.log(`${process.env.REACT_APP_SERVER_LINK}/api/settings/api-get-settings.php`)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/settings/api-get-settings.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setSettings(response.data[0])
        })

    }, [])



    const [loginDialog, setLoginDialog] = useState(false);


    const logout = () => {
        cookies.remove('jwt', { path: '/' });
        navigate("/");
        // setAnchorEl(null);
        window.location.reload()
    }
    return (
        <HeaderContext.Provider value={
            {
                settings,
                loginDialog, setLoginDialog,
                authorized, setAuthorized,
                logout
            }
        }>
            <Header mobileSize={mobileSize} tabletSize={tabletSize} username={username} />
            <Routes>
                <Route path='*' element={<Home id={UUID} authorized={authorized} mobileSize={mobileSize} tabletSize={tabletSize} />} />

                <Route path="/" element={<Home id={UUID} authorized={authorized} mobileSize={mobileSize} tabletSize={tabletSize} />} exact></Route>
                <Route path="/search" element={<Search id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/account/profile" element={<Profile id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/account/favorite" element={<Favorite id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/account/announce" element={<Announce id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/account/Monitored-searches" element={<SavedLinks id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/account/chat" element={<Chat id={UUID} authorized={authorized} />} exact></Route>


                <Route path="/item/:link" element={<Item id={UUID} authorized={authorized} />} exact></Route>

                <Route path="/add" element={<AddProduct authorized={authorized} />} exact></Route>
                <Route path="/add/horse" element={<AddHorse id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/add/equipment" element={<AddEquipment id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/add/services" element={<AddServices id={UUID} authorized={authorized} />} exact></Route>
                <Route path="/confirmation" element={<Confirmation id={UUID} authorized={authorized} />} exact></Route>



                <Route path="/edit/:link" element={<Edit id={UUID} authorized={authorized} />} exact></Route>



                <Route path="/terms-of-use" element={<IntegrationPolicy />} exact></Route>
                <Route path="/personal-data-policy" element={<PersonalDataPolicy />} exact></Route>


                <Route path="/re-password" element={<RePassword authorized={authorized} />} exact></Route>
                <Route path="/register" element={<Register authorized={authorized} />} exact></Route>
                <Route path="/change-password/:vkey" element={<ChangePassword authorized={authorized} />} exact></Route>
                <Route path="/verify/:vkey" element={<VerifyEmail authorized={authorized} />} exact></Route>



            </Routes>
            <Footer />
            <Login />
            <CookiesBannaer />
        </HeaderContext.Provider>
    )
}

export default App
